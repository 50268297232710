import _mergeJSXProps from"@vue/babel-helper-vue-jsx-merge-props";// Types
import mixins from"vue-typed-mixins";export const GboxMediaUploadModalEvents=typedModal();// Enums
import{Modals}from"../../enums/Modals";// Utils
import{typedModal}from"../../utils/typedModals";import{preventDefault}from"../../utils/jsxHelpers";// Mixins
import{WithRefs}from"../../utils/withRefs";// Components
import AdminButton from"../ui/AdminButton";import CmsSpinner from"../ui/CmsSpinner";// Main
const GboxMediaUpload=mixins(WithRefs(),GboxMediaUploadModalEvents).extend({data(){return{// form: {
//   title: '',
//   description: '',
// },
uploadProgress:null,loading:!1,uploadProgresses:[],fileList:[]// Array to store the selected files
}},computed:{allFileNames(){// Extract filenames from the fileList and join them into a string
// @ts-ignore
return 0<this.fileList.length?// @ts-ignore
this.fileList.map(a=>a.name).join(", "):0}},methods:{handleFileChange(a){this.fileList=Array.from(a.target.files)},resetForm(){this.uploadProgress=null},async readFile(a){const b=new Promise((b,c)=>{const d=d=>{var e;null!==(e=d.target)&&void 0!==e&&e.result?b({file:d.target.result}):c({message:this.$t("error.readFile",{entity:a.name})})},e=new FileReader;e.readAsArrayBuffer(a),e.onload=d});return b},async readAllFiles(a){const b=a.map(a=>this.readFile(a));try{const a=await Promise.all(b);return a}catch(a){return void this.$toast.error(a.message)}},async uploadFilesToBucket(a,b,c){if(!c.errored){const a=c.data.map((a,c)=>{const d=a.url,e="string"==typeof d?d:d.url,f=b[c].file;return this.$axios.put(e,f,{headers:{"Content-Type":"application/octet-stream"},onUploadProgress:()=>{// if (!progressEvent.lengthComputable) return
// const uploadPercent =
//   (progressEvent.loaded / progressEvent.total) * 100
//
// this.progress = Math.floor(uploadPercent)
}})});try{const b=await Promise.all(a);return b}catch(a){return void this.$toast.error(a.message)}}},async addFromBucketToMedia(a,b){if(!b.errored){const c=b.data.map((b,c)=>{const d=a[c].name;return{filename:d,path:b.path,isPublic:!0,customData:{directoryId:"cms",access:"public",resize:!0,title:d,description:d}}});return await this.$api.media.file.addFromBucketMultiple(// @ts-ignore
c)}},async createMedia(a){const b=a.map(a=>{const b=a.userFileId;// Create media
return this.$api.cms.media.create(b)});return await Promise.all(b)},async submit(){var a;this.loading=!0;const b=this.$refs.upload,c=null===b||void 0===b||null===(a=b.files)||void 0===a?void 0:a.length;if(!c)return this.showInfoToast("core.notifications.noMediaSelected"),void(this.loading=!1);if(20<c)return this.showInfoToast("core.notifications.tooManyFilesAtOnce"),void(this.loading=!1);const d=Array.from(b.files),e=d.map(a=>({filename:a.name})),f=await this.$api.media.file.getMultipleUploadUrls({files:e,"g-recaptcha-response":""});/* let token = null
        try {
          await this.$recaptcha.reset()
          token = await this.$recaptcha.getResponse()
          await this.$recaptcha.reset()
        } catch (e) {
          console.log(e)
          this.$toast.error('Recaptcha error')
          this.loading = false
          return
        }
        */if(f.errored)return this.loading=!1,void this.showErrorToast("error.uploadUrlFetchFailed");// Convert each file to ArrayBuffer
const g=await this.readAllFiles(d);if(!(null!==g&&void 0!==g&&g.length))return void(this.loading=!1);// Upload ArrayBuffer files to bucket
const h=await this.uploadFilesToBucket(d,// @ts-ignore
g,f);if(!(null!==h&&void 0!==h&&h.length))return this.loading=!1,void this.showErrorToast("error.uploadToBucketFailed");// Add from bucket to cms media
const i=await this.addFromBucketToMedia(d,f);if(null!==i&&void 0!==i&&i.errored)return this.loading=!1,void this.showErrorToast("error.addFromBucketFailed");// Create media
const j=await this.createMedia(i.data);!j.find(a=>a.errored)?this.showSuccessToast("core.notifications.uploaded"):this.showInfoToast("core.notifications.mediaPartiallyUploaded"),this.loading=!1,this.$emit("media-uploaded"),this.$modal.hide(Modals.MediaUpload),this.fileList=[]},showInfoToast(a){this.$toast.info(this.$t(a))},showSuccessToast(a){this.$toast.success(this.$t(a))},showErrorToast(a){this.$toast.error(this.$t(a))}},render(){const a=arguments[0];return a("modal",_mergeJSXProps([{attrs:{name:Modals.MediaUpload,id:Modals.MediaUpload,clickToClose:!1,height:"auto"}},{on:{"before-open":()=>this.resetForm()}}]),[a("form",{class:"p-2",on:{submit:preventDefault(this.submit)}},[a("div",{class:"p-2 text-xl font-medium"},[this.$at("core.modals.details")]),a("div",[a("div",{class:"upload-btn-wrapper"},[a("button",{class:"btnr cursor-pointer"},[this.$at("core.modals.choseFiles")]),a("span",{class:"cursor-pointer"},[0===this.allFileNames?this.$at("core.modals.noFilesChosen"):1<this.fileList.length?`${this.fileList.length} ${this.$at("core.modals.files")}`:this.allFileNames]),a("input",{attrs:{id:"input",title:0===this.allFileNames?"":this.allFileNames,type:"file",multiple:!0},ref:"upload",class:"p-2",on:{change:this.handleFileChange}})]),null===this.uploadProgress?this.loading?a("div",{class:"p-2"},[a(CmsSpinner)]):a("div",{class:"p-2 flex justify-end space-x-2"},[a(AdminButton,{attrs:{secondary:!0},on:{click:()=>{this.fileList=[],this.$modal.hide(Modals.MediaUpload)}}},[this.$at("core.action.cancel")]),a(AdminButton,{attrs:{type:"submit"}},[this.$at("core.action.save")])]):a("div",{class:"flex items-center p-2"},[a("div",{class:"relative flex-grow h-2 bg-gray-200 mr-4"},[a("div",{class:"absolute top-0 bottom-0 left-0 bg-blue-400",style:{width:this.uploadProgress+"%"}})]),a("div",[this.uploadProgress+"%"])])])])])}});export default GboxMediaUpload;